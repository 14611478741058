import { lazy } from 'react';
import { ERoutePermissions } from 'router/config';
import { TRoute } from './types';

export const routes: TRoute = {
  main: {
    path: '',
    isOutlet: true,
    title: 'leftSidebar.nav.users',
    children: {
      infographic: {
        path: 'infographic',
        permissions: [ERoutePermissions.MAIN_USERS],
        title: 'leftSidebar.nav.infographic',
        component: lazy(() =>
          import('pages/Infographic').then(({ Infographic }) => ({
            default: Infographic,
          })),
        ),
      },
      users: {
        path: 'user',
        isOutlet: true,
        permissions: [ERoutePermissions.MAIN_USERS],
        title: 'leftSidebar.nav.users',
        children: {
          table: {
            path: '',
            component: lazy(() =>
              import('pages/Users/UserTable').then(({ UserTable }) => ({
                default: UserTable,
              })),
            ),
            title: '',
          },
          create: {
            path: 'create',
            component: lazy(() =>
              import('pages/Users/UserUpdate').then(({ UserUpdate }) => ({
                default: UserUpdate,
              })),
            ),
            title: '',
          },
          update: {
            path: 'update/:userId',
            component: lazy(() =>
              import('pages/Users/UserUpdate').then(({ UserUpdate }) => ({
                default: UserUpdate,
              })),
            ),
            title: '',
          },
        },
      },
      access: {
        path: 'access',
        permissions: [
          ERoutePermissions.MAIN_ROLES,
          ERoutePermissions.MAIN_SCOPES,
          ERoutePermissions.MAIN_PERMISSIONS,
        ],
        title: 'leftSidebar.nav.access.title',
        isOutlet: true,
        isDropdown: true,
        children: {
          scopes: {
            title: 'leftSidebar.nav.access.scopes',
            permissions: [ERoutePermissions.MAIN_SCOPES],
            path: 'scope',
            isOutlet: true,
            children: {
              table: {
                path: '',
                component: lazy(() =>
                  import('pages/Scope/ScopeTable').then(({ ScopeTable }) => ({
                    default: ScopeTable,
                  })),
                ),
                title: '',
              },
              create: {
                path: 'create',
                component: lazy(() =>
                  import('pages/Scope/ScopeUpdate').then(({ ScopeUpdate }) => ({
                    default: ScopeUpdate,
                  })),
                ),
                title: '',
              },
              update: {
                path: 'update/:scopeId',
                component: lazy(() =>
                  import('pages/Scope/ScopeUpdate').then(({ ScopeUpdate }) => ({
                    default: ScopeUpdate,
                  })),
                ),
                title: '',
              },
            },
          },
          roles: {
            title: 'leftSidebar.nav.access.roles',
            permissions: [ERoutePermissions.MAIN_ROLES],
            path: 'role',
            isOutlet: true,
            children: {
              table: {
                path: '',
                component: lazy(() =>
                  import('pages/Role/RoleTable').then(({ RoleTable }) => ({
                    default: RoleTable,
                  })),
                ),
                title: '',
              },
              create: {
                path: 'create',
                component: lazy(() =>
                  import('pages/Role/RoleUpdate').then(({ RoleUpdate }) => ({
                    default: RoleUpdate,
                  })),
                ),
                title: '',
              },
              update: {
                path: 'update/:roleId',
                component: lazy(() =>
                  import('pages/Role/RoleUpdate').then(({ RoleUpdate }) => ({
                    default: RoleUpdate,
                  })),
                ),
                title: '',
              },
            },
          },
          permissions: {
            title: 'leftSidebar.nav.access.permissions',
            permissions: [ERoutePermissions.MAIN_PERMISSIONS],
            path: 'permission',
            isOutlet: true,
            children: {
              table: {
                path: '',
                component: lazy(() =>
                  import('pages/Permission/PermissionTable').then(
                    ({ PermissionTable }) => ({
                      default: PermissionTable,
                    }),
                  ),
                ),
                title: '',
              },
              create: {
                path: 'create',
                component: lazy(() =>
                  import('pages/Permission/PermissionUpdate').then(
                    ({ PermissionUpdate }) => ({
                      default: PermissionUpdate,
                    }),
                  ),
                ),
                title: '',
              },
              update: {
                path: 'update/:permissionId',
                component: lazy(() =>
                  import('pages/Permission/PermissionUpdate').then(
                    ({ PermissionUpdate }) => ({
                      default: PermissionUpdate,
                    }),
                  ),
                ),
                title: '',
              },
            },
          },
        },
      },
      services: {
        path: 'services',
        permissions: [
          ERoutePermissions.MAIN_ROLES,
          ERoutePermissions.MAIN_SCOPES,
          ERoutePermissions.MAIN_PERMISSIONS,
        ],
        title: 'leftSidebar.nav.services.title',
        isOutlet: true,
        isDropdown: true,
        children: {
          vms: {
            title: 'leftSidebar.nav.services.vms',
            permissions: [ERoutePermissions.MAIN_SCOPES],
            path: 'vms',
            children: {
              table: {
                path: '',
                component: lazy(() =>
                  import('pages/Vms/VmsTable').then(({ VmsTable }) => ({
                    default: VmsTable,
                  })),
                ),
                title: '',
              },
            },
          },
          projects: {
            title: 'leftSidebar.nav.services.projects',
            permissions: [ERoutePermissions.MAIN_SCOPES],
            path: 'projects',
            isOutlet: true,
            children: {
              table: {
                path: '',
                component: lazy(() =>
                  import('pages/Projects/ProjectsTable').then(
                    ({ ProjectsTable }) => ({
                      default: ProjectsTable,
                    }),
                  ),
                ),
                title: '',
              },
              create: {
                path: 'create',
                component: lazy(() =>
                  import('pages/Projects/ProjectsUpdate').then(
                    ({ ProjectsUpdate }) => ({
                      default: ProjectsUpdate,
                    }),
                  ),
                ),
                title: '',
              },
            },
          },
        },
      },
    },
  },
};
