import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { Box, VStack } from '@chakra-ui/react';
import { authPagesModel } from 'pages/Auth/model';
import { Text } from 'shared/ui/atoms/Text';

export const SecondStep = () => {
  const otpInfo = useUnit(authPagesModel.generateOtp.stores.$otpInfo);
  const { t } = useTranslation();

  return (
    <VStack>
      <Box width='90px' height='90px' marginBottom={10}>
        <img src={otpInfo.otpAuthUrl} alt='qr' />
      </Box>

      <Text size='small' props={{ fontWeight: 500 }}>
        {otpInfo.otpSecret}
      </Text>

      <Text size='small' props={{ textAlign: 'center' }}>
        {t('autentication.step2.description')}
      </Text>
    </VStack>
  );
};
