import { ListItem } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { leftSidebarIcons } from 'widgets/Layout/config';
import { parseToPathLink } from 'widgets/Layout/lib/parseToPathLink';
import { INavItem } from 'widgets/Layout/type';

export const NavItem: FC<INavItem> = ({ route, keyLabel }) => {
  const { t } = useTranslation();
  const renderLiItem = ({ isActive }) => (
    <ListItem
      display='flex'
      alignItems='center'
      userSelect='none'
      gap={2}
      marginBottom={2}
      padding={2}
      background={isActive ? 'gray.100' : 'none'}>
      {leftSidebarIcons[keyLabel]}
      <p> {t(route.title)}</p>
    </ListItem>
  );
  return <NavLink to={parseToPathLink(route)}>{renderLiItem}</NavLink>;
};
