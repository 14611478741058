import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { Button } from 'shared/ui/atoms/Button';
import { CustomModal } from 'shared/ui/atoms/CustomModal';

import { Flex } from '@chakra-ui/react';
import { authModel } from 'entities/Auth/model';
import { Text } from 'shared/ui/atoms/Text';
import { logoutModal } from './model/modal';

export const LogoutModal = () => {
  const { t } = useTranslation();
  const open = useUnit(logoutModal.$store);

  const onCancelClick = () => {
    logoutModal.setVisible(false);
  };

  const onSignOutClick = () => {
    authModel.logout.events.logoutEvent();
  };

  return (
    <CustomModal
      isOpen={open}
      footer={
        <Flex gap={4}>
          <Button color='secondary' onClick={onCancelClick}>
            {t('buttonsCommon.cancel')}
          </Button>
          <Button color='warning' onClick={onSignOutClick}>
            {t('logout.logout')}
          </Button>
        </Flex>
      }>
      <Text props={{ textAlign: 'center' }} size='x-large'>
        {t('logout.title')}
      </Text>
    </CustomModal>
  );
};
