import React from 'react';
import { useUnit } from 'effector-react';
import { nanoid } from 'nanoid';
import { useNavigate } from 'react-router-dom';

import { $results, onResultClick } from 'entities/Search/model/search';
import { defaultSearchMap } from 'entities/Search/config/searchMap';
import { TSearchMapItem } from 'entities/Search/types';

import { wrapperClass, resultItemClass } from './style';

const SearchResults: React.FC = () => {
  const navigate = useNavigate();
  const results = useUnit($results);
  const mapResults = results?.length ? results : defaultSearchMap;

  const onResultClickFn = (result?: TSearchMapItem) => {
    onResultClick();
    navigate(result.pathname);
    if (result?.action) result.action();
  };

  return (
    <div className={wrapperClass}>
      {mapResults?.map((result) => (
        <div
          onClick={() => onResultClickFn(result)}
          key={nanoid()}
          className={resultItemClass}>
          {result.title}
        </div>
      ))}
    </div>
  );
};

export default SearchResults;
