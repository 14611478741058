import { createEffect, createEvent, sample } from 'effector';
import { otpFormFactory } from 'entities/Auth/lib/otpFormFactory';
import { EnableOtpMutationVariables } from 'shared/api/apollo/__generated__';
import { requestsClient } from 'shared/api/client';
import { login } from './login';
import { GLOBAL_SPACE } from 'shared/config/regulars';
import { otpSteps } from './otpSteps';

const form = otpFormFactory();

const enableOTP = createEvent<EnableOtpMutationVariables>();
const enableOTPFx = createEffect<EnableOtpMutationVariables, boolean>(
  async (params) => {
    const res = await requestsClient.enableOtp(params);
    return res.enableOtp;
  },
);

sample({
  clock: form.formValidated,
  source: login.stores.$loginData,
  fn: (loginData, form) => ({
    input: {
      userId: loginData.user.id,
      token: form.pinCode.replace(GLOBAL_SPACE, ''),
    },
  }),
  target: enableOTP,
});

sample({
  clock: enableOTP,
  target: enableOTPFx,
});

sample({
  clock: enableOTPFx.doneData,
  fn: () => 4,
  target: otpSteps.events.setCurrentStep,
});

export const enableOtp = {
  events: {
    enableOTP,
  },
  form,
};
