import { differenceInMinutes } from 'date-fns';
import { EExecuteRequestType, TQueryCacheParams } from './types';

export class QueryCache {
  private static storageName = '@queryCache';

  static delete(methodName: string, input: any) {
    const storage = JSON.parse(localStorage.getItem(QueryCache.storageName));
    delete storage[methodName][input];
    localStorage.setItem(QueryCache.storageName, JSON.stringify(storage));
  }

  static get(methodName: string, input: any) {
    const storage = JSON.parse(localStorage.getItem(QueryCache.storageName));
    const isStorageNull = !storage || !storage[methodName];
    if (isStorageNull) return null;

    const record = storage[methodName][JSON.stringify(input)];
    if (!record) return null;
    if (differenceInMinutes(Date.now(), record.setAt) > 1) {
      QueryCache.delete(methodName, input);
      return null;
    }
    return record.response;
  }

  static set(methodName: string, params: TQueryCacheParams) {
    const storage = JSON.parse(localStorage.getItem(QueryCache.storageName));
    const queryCache = {
      ...storage,
      [methodName]: {
        ...storage?.[methodName],
        [JSON.stringify(params.input)]: {
          response: { ...params.response },
          setAt: Date.now(),
        },
      },
    };
    localStorage.setItem(QueryCache.storageName, JSON.stringify(queryCache));
  }

  static clearCache() {
    localStorage.removeItem(QueryCache.storageName);
  }
}

export const executeRequest = async (
  type: EExecuteRequestType,
  request: any,
  params: any,
) => {
  const requestName = request.name;
  if (type === EExecuteRequestType.GET) {
    const storageResponse = QueryCache.get(requestName, params);
    if (storageResponse) return storageResponse;
    const queryResponse = await request(params);
    QueryCache.set(requestName, {
      input: params,
      response: queryResponse,
    });
    return queryResponse;
  }
};
