import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { DefaultTheme } from './type';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

export const customTheme = extendTheme({
  config,
  components: {
    Button: {
      baseStyle: {
        transition: 'all 0.2s ease-in-out',
      },
    },
    Input: {
      baseStyle: {
        field: {
          transition: 'all 0.2s ease-in-out',
        },
      },
    },
    Textarea: {
      baseStyle: {
        transition: 'all 0.2s ease-in-out',
      },
    },
    CheckBox: {
      baseStyle: {
        field: {
          transition: 'all 0.2s ease-in-out',
        },
      },
    },
    RadioGroup: {
      baseStyle: {
        field: {
          transition: 'all 0.2s ease-in-out',
        },
      },
    },
    RadioButton: {
      baseStyle: {
        field: {
          transition: 'all 0.2s ease-in-out',
        },
      },
    },
  },
});

export const lightTheme: DefaultTheme = {
  spinner: {
    color: 'rgba(255, 255, 255, 0.2)',
  },
  search: {
    container: {
      boxShadow:
        '0px 1px 2px rgba(128,138,157,.12),0px 8px 32px rgba(128,138,157,.24);',
    },
    input: {
      bg: '#eff2f5',
    },
  },
  transactions: {
    stats: {
      graph: {
        tooltipFilter:
          'drop-shadow(0px 4.745809555053711px 9.491619110107422px rgba(50, 50, 71, 0.06)) drop-shadow(0px 4.745809555053711px 4.745809555053711px rgba(50, 50, 71, 0.08))',
      },
    },
  },
  leftSidebar: {
    input: {
      border: '#636566',
      color: '#FFFFFF',
      placeholder: '#636566',
    },
    nav: {
      dropdown: {
        arrowColor: '#FFFFFF',
      },
      active: {
        background: '#0000001a',
      },
      default: {
        background: '#FFFFFF',
      },
    },
    textColor: '#636566',
  },
  modalBoxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
  commonBackground: {
    bg1: '#1A1C1E',
    bg2: '#FFFFFF',
    bg3: '#e4e4e4',
  },
  text: {
    spanColor: '#888888',
    subtextColor: '#B6B9C9',
    textColor: '#1F1F27',
    headerColor: '#000000',
    semitextColor: '#81869A',
  },
  backgrounds: {
    layout: '#F6F6F6',
    row: {
      head: '#E7ECFD',
      body: '#F2F2F2',
    },
  },
  colors: {
    grey: '#81869A',
    blue: '#329AFB',
    white: '#FFFFFF',
    black: '#000000',
  },
  link: {
    color: '#3b83f6',
  },
  button: {
    primary: {
      default: {
        background: '#3b83f6',
        color: '#FFFFFF',
      },
      hover: {
        background: '#5a9fff',
      },
      disabled: {
        background: '#a3bffb',
      },
    },
    secondary: {
      default: {
        background: '#e9edf3',
        color: '#1f2937',
      },
      hover: {
        background: '#d3d8e1',
      },
      disabled: {
        background: '#c5cbd3',
        color: '#a1a7af',
      },
    },
    warning: {
      default: {
        background: '#EB5757',
        color: '#FFFFFF',
        border: '#EB5757',
      },
      hover: {
        background: '#f06e6e',
      },
      disabled: {
        background: '#ed8e8e',
      },
    },
  },
  radioButton: {
    color: '#B6B9C9',
  },
  error: {
    color: '#FA4C4C',
    background: '#F1E7E4',
    borderColor: '#FA4C4C',
    boxShadow: 'inset 3px 3px 10px rgba(187, 79, 44, 0.2)',
  },
  input: {
    default: {
      borderColor: '#B8B8B8',
      labelColor: '#1F1F27',
    },
    hover: {
      borderColor: '#E5E7E9',
    },
    focus: {
      borderColor: '#3b83f6',
      labelColor: '#3b83f6',
    },
    disabled: {
      borderColor: '#c5cbd3',
      labelColor: '#c5cbd3',
    },
    error: {
      borderColor: '#EB5757',
      labelColor: '#EB5757',
      iconColor: '#EB5757',
    },
    textColor: '#1A1C1E',
    placeholderColor: '#B8B8B8',
    iconBackground: '#81869A',
  },
  select: {
    default: {
      color: '#B8B8B8',
      borderColor: '#B8B8B8',
    },
    disabled: {
      color: '#c5cbd3',
      borderColor: '#c5cbd3',
    },
    focus: {
      color: '#3b83f6',
      borderColor: '#3b83f6',
    },
    error: {
      borderColor: '#EB5757',
      color: '#EB5757',
    },
    tile: {
      hover: 'rgba(152, 170, 228, 0.12)',
      active: 'rgba(54, 97, 235, 0.12)',
    },
    placeholderColor: '#B8B8B8',
    textColor: '#1A1C1E',
  },
  checkbox: {
    active: {
      background: '#3b83f6',
    },
    inactive: {
      background: '#c5cbd3',
    },
    markColor: '#FFFFFF',
  },
  switch: {
    active: {
      background: '#3b83f6',
      circle: '#FFFFFF',
    },
    inactive: {
      background: '#c5cbd3',
      circle: '#FFFFFF',
    },
  },
  table: {
    buttonColor: '#3b83f6',
    buttonTextColor: '#FFFFFF',
    buttonDisabledColor: '#B8B8B8',
    tableTrOdd: '#f4f4f4',
    tableText: '#1A1C1E',
  },
  toast: {
    success: {
      background: 'rgba(39, 174, 96, 0.12)',
      color: '#27ae60',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
    },
    error: {
      background: 'rgba(235, 87, 87, 0.12)',
      color: '#eb5757',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
    },
    warning: {
      background: 'rgba(242, 153, 74, 0.12)',
      color: '#f2994a',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
    },
    info: {
      background: 'rgba(54, 97, 235, 0.12)',
      color: '#3b83f6',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
    },
  },
  authentication: {
    description: '#888888',
    text: '#FFFFFF',
    stepperBg: 'rgba(136, 136, 136, 0.2)',
    stepperBgActive: '#3b83f6',
    textInactiveStepper: 'rgba(0, 0, 0, 0.4)',
    cardBackground: 'rgba(255, 255, 255, 0.4)',
  },
};

export const darkTheme: DefaultTheme = {
  spinner: {
    color: 'rgba(255, 255, 255, 0.2)',
  },
  search: {
    container: {
      boxShadow:
        '0px 1px 2px rgba(128,138,157,.12),0px 8px 32px rgba(128,138,157,.24);',
    },
    input: {
      bg: '#eff2f5',
    },
  },
  transactions: {
    stats: {
      graph: {
        tooltipFilter:
          'drop-shadow(0px 4.745809555053711px 9.491619110107422px rgba(50, 50, 71, 0.06)) drop-shadow(0px 4.745809555053711px 4.745809555053711px rgba(50, 50, 71, 0.08))',
      },
    },
  },
  leftSidebar: {
    input: {
      border: '#636566',
      color: '#FFFFFF',
      placeholder: '#636566',
    },
    nav: {
      dropdown: {
        arrowColor: '#FFFFFF',
      },
      active: {
        background: '#0000001a',
      },
      default: {
        background: '#FFFFFF',
      },
    },
    textColor: '#636566',
  },
  modalBoxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
  commonBackground: {
    bg1: '#1A1C1E',
    bg2: '#FFFFFF',
    bg3: '#e4e4e4',
  },
  text: {
    spanColor: '#888888',
    subtextColor: '#B6B9C9',
    textColor: '#1F1F27',
    headerColor: '#000000',
    semitextColor: '#81869A',
  },
  backgrounds: {
    layout: '#F6F6F6',
    row: {
      head: '#E7ECFD',
      body: '#F2F2F2',
    },
  },
  colors: {
    grey: '#81869A',
    blue: '#329AFB',
    white: '#FFFFFF',
    black: '#000000',
  },
  link: {
    color: '#3b83f6',
  },
  button: {
    primary: {
      default: {
        background: '#3b83f6',
        color: '#FFFFFF',
      },
      hover: {
        background: '#5a9fff',
      },
      disabled: {
        background: '#a3bffb',
      },
    },
    secondary: {
      default: {
        background: '#e9edf3',
        color: '#1f2937',
      },
      hover: {
        background: '#d3d8e1',
      },
      disabled: {
        background: '#c5cbd3',
        color: '#a1a7af',
      },
    },
    warning: {
      default: {
        background: '#EB5757',
        color: '#FFFFFF',
        border: '#EB5757',
      },
      hover: {
        background: '#f06e6e',
      },
      disabled: {
        background: '#ed8e8e',
      },
    },
  },
  radioButton: {
    color: '#B6B9C9',
  },
  error: {
    color: '#FA4C4C',
    background: '#F1E7E4',
    borderColor: '#FA4C4C',
    boxShadow: 'inset 3px 3px 10px rgba(187, 79, 44, 0.2)',
  },
  input: {
    default: {
      borderColor: '#B8B8B8',
      labelColor: '#1F1F27',
    },
    hover: {
      borderColor: '#E5E7E9',
    },
    focus: {
      borderColor: '#3b83f6',
      labelColor: '#3b83f6',
    },
    disabled: {
      borderColor: '#c5cbd3',
      labelColor: '#c5cbd3',
    },
    error: {
      borderColor: '#EB5757',
      labelColor: '#EB5757',
      iconColor: '#EB5757',
    },
    textColor: '#1A1C1E',
    placeholderColor: '#B8B8B8',
    iconBackground: '#81869A',
  },
  select: {
    default: {
      color: '#B8B8B8',
      borderColor: '#B8B8B8',
    },
    disabled: {
      color: '#c5cbd3',
      borderColor: '#c5cbd3',
    },
    focus: {
      color: '#3b83f6',
      borderColor: '#3b83f6',
    },
    error: {
      borderColor: '#EB5757',
      color: '#EB5757',
    },
    tile: {
      hover: 'rgba(152, 170, 228, 0.12)',
      active: 'rgba(54, 97, 235, 0.12)',
    },
    placeholderColor: '#B8B8B8',
    textColor: '#1A1C1E',
  },
  checkbox: {
    active: {
      background: '#3b83f6',
    },
    inactive: {
      background: '#c5cbd3',
    },
    markColor: '#FFFFFF',
  },
  switch: {
    active: {
      background: '#3b83f6',
      circle: '#FFFFFF',
    },
    inactive: {
      background: '#c5cbd3',
      circle: '#FFFFFF',
    },
  },
  table: {
    buttonColor: '#3b83f6',
    buttonTextColor: '#FFFFFF',
    buttonDisabledColor: '#B8B8B8',
    tableTrOdd: '#f4f4f4',
    tableText: '#1A1C1E',
  },
  toast: {
    success: {
      background: 'rgba(39, 174, 96, 0.12)',
      color: '#27ae60',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
    },
    error: {
      background: 'rgba(235, 87, 87, 0.12)',
      color: '#eb5757',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
    },
    warning: {
      background: 'rgba(242, 153, 74, 0.12)',
      color: '#f2994a',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
    },
    info: {
      background: 'rgba(54, 97, 235, 0.12)',
      color: '#3b83f6',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
    },
  },
  authentication: {
    description: '#888888',
    text: '#FFFFFF',
    stepperBg: 'rgba(136, 136, 136, 0.2)',
    stepperBgActive: '#3b83f6',
    textInactiveStepper: 'rgba(255, 255, 255, 0.2)',
    cardBackground: 'rgba(255, 255, 255, 0.4)',
  },
};

const colors = {
  light: lightTheme,
  dark: darkTheme,
};

export const theming = (cb: (allCollor: typeof colors.light) => void) =>
  Object.keys(colors).reduce(
    (acc, name) =>
      Object.assign(acc, {
        [`.theme-${name} &`]: cb(colors[name]),
      }),
    {},
  );
