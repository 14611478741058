import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Auth } from 'pages/Auth';
import { endPoints } from 'router/endPoints';
import { routes } from 'router/routes';
import { QueryCache } from 'shared/lib/queryCache/QueryCache';
import { globalStyles } from 'shared/theme/GlobalStyles';
import Layout from 'widgets/Layout';
import { mapRoutes } from 'widgets/RouteGuard/lib/mapRoutes';
import { ErrorBoundary } from './ErrorBoundary';

import { Box, ChakraProvider } from '@chakra-ui/react';
import { authModel } from 'entities/Auth/model';
import { profileModel } from 'entities/Profile/model';
import { ConfirmEmail } from 'pages/ConfirmEmail';
import { customTheme } from 'shared/theme';
import { ETheme } from 'shared/config/enums';

const clearLocalStorage = () => {
  QueryCache.clearCache();
};

const App = () => {
  const [isAuth] = useUnit([authModel.isAuth.stores.$isAuth]);
  useEffect(() => {
    window.addEventListener('beforeunload', clearLocalStorage);
    return () => {
      window.removeEventListener('beforeunload', clearLocalStorage);
    };
  }, []);
  useEffect(() => {
    authModel.isAuth.events.initAuth();
  }, []);

  useEffect(() => {
    if (isAuth) profileModel.selfUser.events.getSelfUser();
  }, [isAuth]);

  return (
    <ChakraProvider theme={customTheme}>
      <ErrorBoundary>
        <Box
          className={`${globalStyles} ${ETheme.light}`}
          background='gray.100'
          display='flex'
          minWidth='100vw'
          minHeight='100vh'
          justifyContent='center'
          alignItems='center'>
          <Routes>
            <Route
              path={endPoints.CONFIRM_EMAIL}
              element={
                <ErrorBoundary>
                  <ConfirmEmail />
                </ErrorBoundary>
              }
            />
            {isAuth ? (
              <Route path={endPoints.DEFAULT_PAGE} element={<Layout />}>
                {mapRoutes(routes)}
                <Route
                  index
                  element={
                    <ErrorBoundary>
                      <Navigate to='/infographic' />
                    </ErrorBoundary>
                  }
                />
              </Route>
            ) : (
              <>
                <Route
                  path={endPoints.AUTHORIZATION_PAGE}
                  element={
                    <ErrorBoundary>
                      <Auth />
                    </ErrorBoundary>
                  }
                />
              </>
            )}
          </Routes>
        </Box>
      </ErrorBoundary>
    </ChakraProvider>
  );
};

export default App;
