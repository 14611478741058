import { css } from '@linaria/core';

export const buttonClass = css`
  border: none;
  transition: all 0.25s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  width: fit-content;
  position: relative;
`;
