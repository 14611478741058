import React, { FC, useState } from 'react';

import IcEye from 'shared/assets/svg/passwordInput/icEye.svg?react';
import IcEyeClosed from 'shared/assets/svg/passwordInput/icEyeClosed.svg?react';
import { Input } from 'shared/ui/atoms/Input';
import { svgWrapperClass } from './style';
import { IPasswordInput } from './types';

export const PasswordInput: FC<IPasswordInput> = ({
  rightContent,
  inputProps,
  ...params
}) => {
  const [isExposed, setIsExposed] = useState(false);

  const onExposeClick = () => {
    setIsExposed(!isExposed);
  };

  const getRightElement = () => {
    if (!rightContent)
      return (
        <button
          type='button'
          className={svgWrapperClass}
          onClick={onExposeClick}>
          {isExposed ? <IcEye /> : <IcEyeClosed />}
        </button>
      );

    return rightContent(isExposed, onExposeClick);
  };

  return (
    <Input
      {...params}
      inputProps={{
        ...inputProps,
        type: isExposed ? inputProps.type : 'password',
      }}
      rightContent={getRightElement()}
    />
  );
};
