import i18next from 'shared/locales/i18n';
import { TShowToast } from '../types';
import { createStandaloneToast } from '@chakra-ui/react';
export const { ToastContainer, toast } = createStandaloneToast();

export const showToast: TShowToast = ({
  messageKey,
  translateParams,
  status,
  duration = 3000,
}) =>
  toast({
    duration,
    title: i18next.t(messageKey, translateParams),
    status,
    isClosable: true,
  });
