import { createEffect, createEvent, createStore, sample } from 'effector';
import jwtDecode from 'jwt-decode';
import { Permission, User } from 'shared/api/apollo/__generated__/index';
import { requestsClient } from 'shared/api/client';
import { ACCESS_TOKEN_KEY } from 'shared/config/constants';
import { ITokenPayload } from 'shared/config/interfaces';

// ----------------------- Get self user -----------------------
const $selfUser = createStore<User>(null);

const getSelfUser = createEvent();
const getSelfUserFx = createEffect<void, User>(async () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const parsedToken = jwtDecode<ITokenPayload>(accessToken);
  if (!parsedToken) return null;
  const { getUser } = await requestsClient.getUser({
    input: {
      id: parsedToken.sub,
    },
  });
  return getUser as User;
});

sample({
  clock: getSelfUser,
  target: getSelfUserFx,
});
sample({
  clock: getSelfUserFx.doneData,
  target: $selfUser,
});

// ----------------------- Self permissions -----------------------
const $selfPermissions = createStore<Permission[]>(null);

sample({
  clock: $selfUser,
  fn: (user) => user.roles.flatMap((role) => role.role.permissions.nodes),
  target: $selfPermissions,
});

export const selfUser = {
  stores: {
    $selfUser,
    $selfPermissions,
  },
  events: {
    getSelfUser,
  },
};
