import { css } from '@linaria/core';

const link = css`
  text-decoration: none;
`;

const breadcrumbs = css`
  display: flex;
  a {
    text-decoration: none;
  }
`;

const linkName = css`
  text-transform: capitalize;
`;

const linkSeparator = css`
  margin: 0 16px;
`;

export const breadcrumbsCss = {
  link,
  breadcrumbs,
  linkName,
  linkSeparator,
};
