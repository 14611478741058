export enum EReportTableType {
  LIQUIDITY_FROM_FEES = 'LIQUIDITY_FROM_FEES',
  COMMISSIONS = 'COMMISSIONS',
  OPERATION_COSTS_WITHDRAW_FEE = 'OPERATION_COSTS_WITHDRAW_FEE',
  INCOME_DEFI = 'INCOME_DEFI',
  INCOME_POSITION = 'INCOME_POSITION',
  INCOME_PORTFOLIO_POSITION = 'INCOME_PORTFOLIO_POSITION',
  INVESTED_FUNDS_PORTFOLIO_POSITION = 'INVESTED_FUNDS_PORTFOLIO_POSITION',
  INVESTED_FUNDS_POSITION = 'INVESTED_FUNDS_POSITION',
  INVESTED_FUNDS_DEFI = 'INVESTED_FUNDS_DEFI',
  SERVICE_ACCOUNTS = 'SERVICE_ACCOUNTS',
}
