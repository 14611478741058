import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'effector-forms';

import { authPagesModel } from 'pages/Auth/model';
import { PasswordInput } from 'shared/ui/molecules/PasswordInput';
import { TOnChange, TOnSubmit } from 'shared/config/types';
import { Button } from 'shared/ui/atoms/Button';
import { Input } from 'shared/ui/atoms/Input';
import { Card } from 'shared/ui/atoms/Card';

export const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const { fields, submit, errorText } = useForm(authPagesModel.login.form);

  const onChange: TOnChange = (e) => {
    fields[e.target.name].onChange(e.target.value);
  };

  const onSubmit: TOnSubmit = (e) => {
    e.preventDefault();

    submit();
  };

  return (
    <Card
      headerDivider={false}
      props={{ width: '500px' }}
      title='authorization.login.title'>
      <form onSubmit={onSubmit}>
        <Input
          inputProps={{
            name: fields.email.name,
            value: fields.email.value,
            onChange,
            placeholder: t('authorization.login.email'),
          }}
          labelKey='authorization.login.email'
          errorKey={errorText('email')}
        />

        <PasswordInput
          inputProps={{
            name: fields.password.name,
            value: fields.password.value,
            onChange,
            placeholder: t('authorization.login.passwordPlaceholder'),
          }}
          labelKey='authorization.login.password'
          errorKey={errorText('password')}
        />

        <Button props={{ width: '100%' }} color='primary' type='submit'>
          {t('authorization.login.loginBtn')}
        </Button>
      </form>
    </Card>
  );
};
