import { createEvent, createStore, sample } from 'effector';

import { EAuthDevices } from '../types';

const setSelectedDevice = createEvent<EAuthDevices>();
const $selectedDevice = createStore(EAuthDevices.IOS);

sample({
  clock: setSelectedDevice,
  target: $selectedDevice,
});

export const device = {
  stores: {
    $selectedDevice,
  },
  events: {
    setSelectedDevice,
  },
};
