import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const wrapper = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const line = css`
  width: 100%;
  height: 0;
  max-width: 100px;
  margin-top: 12px;

  ${theming((theme) => ({
    border: '1px solid ' + theme.authentication.stepperBg,
  }))}

  &[data-isactive='true'] {
    ${theming((theme) => ({
      border: '1px solid ' + theme.authentication.stepperBgActive,
    }))}
  }
`;

export const stepperCss = {
  wrapper,
  line,
};
