import { createEffect, createEvent, forward } from 'effector';

import { IShowToast } from '../types';
import { showToast } from '../lib/showToast';

const showToastFx = createEffect((params: IShowToast) => {
  showToast(params);
});

const showToastEvent = createEvent<IShowToast>();

forward({
  from: showToastEvent,
  to: showToastFx,
});

export { showToastEvent };
