export const iconIds = {
  common: {
    icPlus: 'icPlus',
    icOptions: 'icOptions',
    icCheckmark: 'icCheckmark',
    icPencil: 'icPencil',
    icTrash: 'icTrash',
    icRightArrow: 'icRightArrow',
    icSpinner: 'icSpinner',
  },
};
