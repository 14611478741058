import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const wrapperContainerClass = css`
  padding: 16px 14px;
  min-width: 400px;
  right: 20px;
  position: absolute;
  border-radius: 8px;
  top: 18px;
  height: 200px;
  z-index: 999;

  ${theming((theme) => ({
    background: theme.colors.white,
    boxShadow: theme.search.container.boxShadow,
  }))}
`;

const inputClass = css`
  width: 100%;
  margin-left: 10px;
  border: 0;
  outline: none;
`;

export { wrapperContainerClass, inputClass };
