import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const wrapperClass = css`
  height: 130px;
  overflow-x: auto;
  margin: 20px 0 0 0;
`;

const resultItemClass = css`
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 6px;
  transition: 0.2s all;
  width: 100%;
  font-size: 14px;

  &:hover {
    ${theming((theme) => ({
      background: theme.search.input.bg,
    }))}
  }
`;

export { wrapperClass, resultItemClass };
