import { useField } from 'effector-forms';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent } from 'react';

import { authPagesModel } from 'pages/Auth/model';
import { EInputFormatType } from 'shared/ui/atoms/Input/types';
import { Input } from 'shared/ui/atoms/Input';
import { thirdStepCss } from './style';
import { PIN_CODE_MASK, PIN_CODE_PATTERN } from 'entities/Auth/config';
import { Box } from '@chakra-ui/react';
import { Text } from 'shared/ui/atoms/Text';

export const ThirdStep: React.FC = () => {
  const { t } = useTranslation();
  const { value, onChange } = useField(
    authPagesModel.enableOtp.form.fields.pinCode,
  );

  const onInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    onChange(ev.target.value);
  };

  return (
    <Box>
      <Input
        format={{
          type: EInputFormatType.PATTERN,
          props: {
            format: PIN_CODE_PATTERN,
            mask: PIN_CODE_MASK,
          },
        }}
        inputProps={{
          value: value,
          type: 'number',
          onChange: onInputChange,
        }}
        classInput={thirdStepCss.input}
        classWrapper={thirdStepCss.inputWrapper}
        labelKey='autentication.step3.label'
        isWithError={false}
      />

      <Text size='small'>{t('autentication.step3.inputDescription')}</Text>
    </Box>
  );
};
