import { useTranslation } from 'react-i18next';
import React from 'react';

import { IStepperItem } from './types';
import { stepperItemCss } from './style';

export const StepperItem: React.FC<IStepperItem> = ({
  titleKey,
  isActive = false,
  number,
}) => {
  const { t } = useTranslation();

  return (
    <div className={stepperItemCss.wrapper}>
      <div data-isactive={isActive} className={stepperItemCss.circle}>
        {number}
      </div>

      <p data-isactive={isActive} className={stepperItemCss.title}>
        {t(titleKey)}
      </p>
    </div>
  );
};
