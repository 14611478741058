import { createEffect, createEvent, createStore, sample } from 'effector';
import { ConfirmEmailMutationVariables } from 'shared/api/apollo/__generated__';
import { requestsClient } from 'shared/api/client';
import { stateEffect } from 'shared/lib/stateEffect';
import { toastEffect } from 'shared/lib/toastEffect';

const confirmEmail = createEvent<string>();

const confirmEmailState = stateEffect(
  toastEffect(
    createEffect<ConfirmEmailMutationVariables, boolean>(async (params) => {
      const res = await requestsClient.confirmEmail(params);
      return res.confirmEmail;
    }),
    { errorMessage: 'confirmEmail.error' },
  ),
);

sample({
  clock: confirmEmail,
  fn: (token) => ({
    input: {
      token,
    },
  }),
  target: confirmEmailState.effect,
});

export const confirm = {
  confirmEmailState,
  events: { confirmEmail },
};
