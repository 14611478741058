import { ISelectOption } from 'shared/ui/atoms/Select/types';

export const PIN_CODE_PATTERN = '### ###';
export const PIN_CODE_MASK = ' ';

export const entriesPerPageOptions: ISelectOption[] = [
  {
    label: '20',
    value: 20,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];
