import {
  InputHTMLAttributes,
  MouseEvent,
  MutableRefObject,
  ReactElement,
} from 'react';
import {
  NumberFormatValues,
  NumericFormatProps,
  PatternFormatProps,
} from 'react-number-format';
import { TLocaleKey } from 'shared/locales/i18n';

export type TErrorRowCount = 1 | 2;

export interface IInput {
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  inputRef?: MutableRefObject<HTMLInputElement>;
  classInput?: string;
  classWrapper?: string;
  classLabel?: string;
  borderClass?: string;
  labelKey?: TLocaleKey;
  errorKey?: string;
  descriptionKey?: TLocaleKey;
  errorRowCount?: TErrorRowCount;
  iconClass?: string;
  isAllowed?: (values: NumberFormatValues) => boolean;
  isWithError?: boolean;
  onValueChange?: (numberValues: NumberFormatValues) => void;
  format?: TInputFormatTypes;
  rightContent?: ReactElement;
  leftContent?: ReactElement;
  isWithSearch?: boolean;
  onClearClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export enum EInputFormatType {
  TEXT = 'text',
  NUMERIC = 'numeric',
  PATTERN = 'pattern',
}

type TInputFormatTypes =
  | {
      type: EInputFormatType.NUMERIC;
      props?: NumericFormatProps;
    }
  | {
      type: EInputFormatType.PATTERN;
      props?: PatternFormatProps;
    }
  | {
      type: EInputFormatType.TEXT;
      props?: never;
    };

export type TInputFormat = {
  type: TInputFormatTypes;
};
