import { routesPermissions } from 'router/config';
import { TFilterRoutesByPermissions } from './type';

export const filterRoutesByPermissions: TFilterRoutesByPermissions = (
  selfPermissions,
  validPermissions,
) =>
  !selfPermissions ||
  !validPermissions?.length ||
  validPermissions
    .reduce(
      (acc, val) => [...acc, ...Object.values(routesPermissions[val])],
      [],
    )
    .some((title) =>
      selfPermissions?.some((selfPermission) => selfPermission.title === title),
    );
