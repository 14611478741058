import { createEvent, createStore, sample } from 'effector';

const setCurrentStep = createEvent<number>();
const $currentStep = createStore<number>(0);

sample({
  clock: setCurrentStep,
  target: $currentStep,
});

export const otpSteps = {
  stores: {
    $currentStep,
  },
  events: {
    setCurrentStep,
  },
};
