import { LeftProfile } from '../LeftProfile';
import { LogoutModal } from '../LogoutModal';

import { Flex, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/ui/atoms/Text';
import { Menu } from '../Menu';

export const LeftSidebar = () => {
  const { t } = useTranslation();
  return (
    <>
      <Stack background='white' width='230px' padding={6}>
        <Flex gap={2} alignItems='center'>
          <Text size='medium' props={{ fontWeight: 500 }}>
            {t('title')}
          </Text>
        </Flex>
        <Stack flexDirection='column' height='100%'>
          <Menu />
          <LeftProfile />
        </Stack>
      </Stack>
      <LogoutModal />
    </>
  );
};
