import { useUnit } from 'effector-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { filterRoutesByPermissions } from '../../lib/filterRoutesByPermissions';
import { IDropdownProps } from './types';

import { Box, ListItem } from '@chakra-ui/react';
import { profileModel } from 'entities/Profile/model';
import { ESpriteName } from 'shared/config/enums';
import { useDropdown } from 'shared/lib/useDropdown';
import { Svg } from 'shared/ui/atoms/Svg';
import { iconIds } from 'shared/ui/atoms/Svg/config';
import { leftSidebarIcons } from 'widgets/Layout/config';

const Dropdown: React.FC<IDropdownProps> = ({ route, keyLabel }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const permissions = useUnit(profileModel.selfUser.stores.$selfPermissions);

  const { ref, refDropdown, setDropdown, dropdown } = useDropdown({
    isClickOutside: false,
  });

  useEffect(() => {
    if (location?.pathname.includes(`${route.path}`)) setDropdown(true);
    else setDropdown(false);
  }, [location]);

  useEffect(() => {}, []);

  const onToggle = (e) => {
    e.preventDefault();
    setDropdown((prev) => !prev);
  };

  return (
    <Box ref={ref}>
      <ListItem
        display='flex'
        alignItems='center'
        userSelect='none'
        gap={2}
        marginBottom={2}
        padding={2}
        onClick={onToggle}>
        {leftSidebarIcons[keyLabel]}
        {t(route?.title)}
        <Svg
          spriteName={ESpriteName.common}
          idIcon={iconIds.common.icRightArrow}
          width={16}
          height={16}
          style={
            dropdown
              ? {
                  transform: 'scale(-1) rotate(270deg)',
                  marginLeft: 'auto',
                  transition: 'all 150ms',
                }
              : {
                  transform: 'scale(1) rotate(270deg)',
                  marginLeft: 'auto',
                  transition: 'all 150ms',
                }
          }
        />
      </ListItem>
      <Box
        ref={refDropdown}
        overflow='hidden'
        transition='all 150ms'
        paddingLeft={8}>
        {Object.values(route.children ?? {})
          .filter((child) =>
            filterRoutesByPermissions(permissions, child.permissions),
          )
          .map((item, index) => {
            const isActive: boolean =
              location.pathname === `/${route.path}/${item.path}`;
            if (item.hide) return null;
            return (
              <NavLink
                key={index}
                to={`/${route.path}/${item.path}`}
                data-is-active={isActive}>
                <ListItem
                  display='flex'
                  alignItems='center'
                  userSelect='none'
                  gap={2}
                  marginBottom={2}
                  padding={2}
                  background={isActive ? 'gray.100' : 'none'}>
                  {t(item.title)}
                </ListItem>
              </NavLink>
            );
          })}
      </Box>
    </Box>
  );
};

export default Dropdown;
