import { Box, Stack, UnorderedList } from '@chakra-ui/react';
import { filterRoutesByPermissions } from 'widgets/Layout/lib/filterRoutesByPermissions';
import { useUnit } from 'effector-react';
import { profileModel } from 'entities/Profile/model';
import { routes } from 'router/routes';
import Dropdown from '../Dropdown';
import { NavItem } from '../NavItem';

export const Menu = () => {
  const selfPermissions = useUnit(
    profileModel.selfUser.stores.$selfPermissions,
  );
  return (
    <Box>
      <Stack marginTop={5}>
        <UnorderedList
          padding={0}
          margin={0}
          fontWeight={500}
          fontSize='medium'>
          {Object.entries(routes['main'].children)
            .filter(([_, { permissions }]) =>
              filterRoutesByPermissions(selfPermissions, permissions),
            )
            .map(([keyLabel, item], index) =>
              item.isDropdown ? (
                <Dropdown
                  key={`Nav_Item_${index}`}
                  route={item}
                  keyLabel={keyLabel}
                />
              ) : (
                <NavItem
                  key={`Nav_Item_${index}`}
                  keyLabel={keyLabel}
                  route={item}
                />
              ),
            )}
        </UnorderedList>
      </Stack>
    </Box>
  );
};
