import { BrowserRouter } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import App from 'app/App';

import './shared/locales/i18n';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'entities/Toast/lib/showToast';

import { createRoot } from 'react-dom/client';
import { ColorModeScript } from '@chakra-ui/react';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <DndProvider backend={HTML5Backend}>
      <ToastContainer />
      <ColorModeScript initialColorMode={'light'} />
      <App />
    </DndProvider>
  </BrowserRouter>,
);
