import { createEffect, createEvent, sample } from 'effector';
import { requestsClient } from 'shared/api/client';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'shared/config/constants';
import { parseJwtToken } from '../lib/parseJwtToken';

const logoutEvent = createEvent();
const logoutFx = createEffect<void, boolean>(async (params) => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  const decoded = parseJwtToken(token);
  const userId = decoded.sub;
  const res = await requestsClient.logout({
    input: {
      userId: Number(userId),
    },
  });
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  window.location.reload();
  return res.logout;
});

sample({
  clock: logoutEvent,
  target: logoutFx,
});

export const logout = {
  events: {
    logoutEvent,
  },
};
