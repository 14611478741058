import { css } from '@linaria/core';
import { theming } from 'shared/theme';

export const wrapperClass = css`
  width: 396px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px 8px;
  font-size: 13px;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  ${theming((theme) => ({
    color: theme.text.spanColor,
    borderColor: theme.leftSidebar.input.border,
    background: theme.search.input.bg,
  }))}
`;

export const hotKeyClass = css`
  width: 20px;
  height: 20px;
  border-radius: 6px;
  margin-left: auto;
  text-align: center;
  line-height: 20px;
  font-weight: 600;

  ${theming((theme) => ({
    color: theme.search.input.bg,
    background: theme.text.spanColor,
  }))}
`;
