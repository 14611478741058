import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';

import SearchContainer from './ui/SearchContainer';
import {
  $searchContainerVisible,
  setSearchContainerVisible,
} from 'entities/Search/model/search';

import { wrapperClass, hotKeyClass } from './style';
import SearchIcon from 'shared/assets/svg/SearchLoupe.svg?react';

const Search: FC = () => {
  const { t } = useTranslation();
  const searchContainerVisible = useUnit($searchContainerVisible);

  useEffect(() => {
    window.addEventListener('keyup', onSlashKeyPress);
    return () => window.removeEventListener('keyup', onSlashKeyPress);
  }, []);

  const onSearchClick = () => {
    setSearchContainerVisible(true);
  };

  const onSlashKeyPress = (e) => {
    if (e.code === 'Slash') setSearchContainerVisible(true);
  };

  return (
    <>
      <button className={wrapperClass} onClick={onSearchClick}>
        <SearchIcon />
        {t('leftSidebar.configs.placeholderSearch')}
        <div className={hotKeyClass}>/</div>
      </button>
      {searchContainerVisible && <SearchContainer />}
    </>
  );
};

export default Search;
