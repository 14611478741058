import { css } from '@linaria/core';

export const globalStyles = css`
  :global() {
    body {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    a {
      color: inherit;
    }

    #root {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      overflow: hidden;
    }
  }
`;

export const inputWrapperClass = css`
  width: 400px !important;
`;
