import { css } from '@linaria/core';
import { theming } from 'shared/theme';

export const textClass = css`
  &[data-color='mediumGray'] {
    ${theming((theme) => ({ color: theme.text.spanColor }))}
  }
  &[data-color='lightGray'] {
    ${theming((theme) => ({ color: theme.text.subtextColor }))}
  }
  &[data-color='semiGray'] {
    ${theming((theme) => ({ color: theme.text.semitextColor }))}
  }
  &[data-color='gray'] {
    ${theming((theme) => ({ color: theme.text.textColor }))}
  }
  &[data-color='darkGray'] {
    ${theming((theme) => ({ color: theme.text.headerColor }))}
  }
  &[data-color='white'] {
    ${theming((theme) => ({ color: theme.colors.white }))}
  }
  &[data-color='blue'] {
    ${theming((theme) => ({ color: theme.colors.blue }))}
  }
  &[data-color='ultramarineBlue'] {
    ${theming((theme) => ({ color: theme.link.color }))}
  }
 
`;
