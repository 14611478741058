import { createEvent, createStore, sample } from 'effector';

const getItemIdName = createEvent<string>();
const $itemIdName = createStore<string>(null);

// sample({
//   clock: getItemIdName,
//   source: {
//     tezroUser: tezroUserModel.$user,
//   },
//   fn: ({ tezroUser }, entityName): string => {
//     if (entityName === 'tezroUserId')
//       return `${tezroUser?.firstName ?? ''} ${tezroUser?.lastName ?? ''}`;
//     return '';
//   },
//   target: $itemIdName,
// });

export { $itemIdName, getItemIdName };
