import { FC } from 'react';

import commonSprite from 'shared/assets/svg/sprites/common/commonSprite.svg?url';

import { defaultSvgClass } from './style';
import { ISvg } from './types';

export const Svg: FC<ISvg> = ({
  idIcon,
  className,
  onClick,
  width,
  height,
  style,
}) => {
  return (
    <svg
      onClick={onClick}
      className={`svg-icon ${defaultSvgClass} ${className}`}
      width={width}
      height={height}
      style={style}>
      <use xlinkHref={`${commonSprite}#${idIcon}`} />
    </svg>
  );
};
