import { createEffect, createEvent, sample } from 'effector';
import { otpFormFactory } from 'entities/Auth/lib/otpFormFactory';
import { showToastEvent } from 'entities/Toast/model/showToast';
import { EToastStatus } from 'entities/Toast/types';
import {
  VerifyOtpMutationVariables,
  VerifyOtpResponse,
} from 'shared/api/apollo/__generated__';
import { requestsClient } from 'shared/api/client';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'shared/config/constants';
import { modalFactory } from 'shared/lib/modalFactory';
import { stage } from './stage';
import { EAuthStages } from '../types';
import { login } from './login';
import { GLOBAL_SPACE } from 'shared/config/regulars';
import { authModel } from 'entities/Auth/model';
import { toastEffect } from 'shared/lib/toastEffect';

const modal = modalFactory(false);
const form = otpFormFactory();

const verifyOTP = createEvent<VerifyOtpMutationVariables>();
const verifyOTPFx = toastEffect(
  createEffect<VerifyOtpMutationVariables, VerifyOtpResponse>(
    async (params) => {
      const res = await requestsClient.verifyOtp(params);
      localStorage.setItem(ACCESS_TOKEN_KEY, res.verifyOtp.accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, res.verifyOtp.refreshToken);
      return res.verifyOtp;
    },
  ),
  {
    errorMessage: 'autentication.errors.invalidCode',
  },
);

sample({
  clock: verifyOTP,
  target: verifyOTPFx,
});

sample({
  clock: form.formValidated,
  source: login.stores.$loginData,
  fn: (data, form) => ({
    input: {
      token: form.pinCode.replace(GLOBAL_SPACE, ''),
      userId: data.user.id,
    },
  }),
  target: verifyOTPFx,
});

sample({
  clock: verifyOTPFx.doneData,
  target: authModel.isAuth.events.setAuth,
});

sample({
  clock: stage.stores.$currentStage,
  filter: (stage) => stage === EAuthStages.VERIFY_OTP,
  fn: () => true,
  target: modal.setVisible,
});

export const verifyOtp = {
  events: {
    verifyOTP,
  },
  form,
  modal,
};
