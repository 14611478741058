import React, { FC } from 'react';
import { IText } from './types';
import { Text as TextComponent } from '@chakra-ui/react';
import { textClass } from './style';

export const Text: FC<IText> = ({
  id,
  className,
  title,
  color = 'gray',
  size = 'medium',
  props,
  children,
}) => {
  return (
    <TextComponent
      data-color={color}
      fontSize={size}
      id={id}
      className={`${textClass} ${className}`}
      title={title}
      {...props}>
      {children}
    </TextComponent>
  );
};
