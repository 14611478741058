import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';

import { authPagesModel } from 'pages/Auth/model';
import { InitialStep } from '../InitialStep';
import { CurrentStep } from '../CurrentStep';
import { Stepper } from '../Stepper';
import { Box, Heading } from '@chakra-ui/react';

export const EnableOTP = () => {
  const currentStep = useUnit(authPagesModel.otpSteps.stores.$currentStep);
  const { t } = useTranslation();

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      {currentStep === 0 ? (
        <InitialStep />
      ) : (
        <>
          <Box width='1000px' marginBottom='40px'>
            <Heading marginBottom='60px' textAlign='center' size='lg'>
              {t('autentication.title')}
            </Heading>

            <Stepper />
          </Box>

          <CurrentStep />
        </>
      )}
    </Box>
  );
};
