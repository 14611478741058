import { createEffect, createEvent, createStore, sample } from 'effector';

import { VerifyOtpResponse } from 'shared/api/apollo/__generated__';
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  TABLE_ENTRIES_PER_PAGE,
} from 'shared/config/constants';
import { entriesPerPageOptions } from '../config';
import { parseJwtToken } from '../lib/parseJwtToken';
import { requestsClient } from 'shared/api/client';
import { pending } from 'patronum';

const $isAuth = createStore(false);

const initAuth = createEvent();
const initAuthFx = createEffect(() => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (!accessToken) throw Error('Credentials expired');
  const parsedToken = parseJwtToken(accessToken);
  if (new Date(parsedToken.exp * 1000).getTime() < Date.now()) {
    throw new Error('Credentials expired');
  }
});

const refreshAuthFx = createEffect(async () => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  if (!refreshToken) throw new Error('Credentials expired');
  const parsedToken = parseJwtToken(refreshToken);
  if (new Date(parsedToken.exp * 1000).getTime() < Date.now()) {
    throw new Error('Credentials expired');
  }
  const res = await requestsClient.refreshTokens({ input: { refreshToken } });
  if (res.refreshTokens) {
    localStorage.setItem(ACCESS_TOKEN_KEY, res.refreshTokens.accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, res.refreshTokens.refreshToken);
  }
});

const clearTokensFx = createEffect(() => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
});

const setAuth = createEvent<VerifyOtpResponse>();
const setAuthFx = createEffect<VerifyOtpResponse, void>((params) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, params.accessToken);
  localStorage.setItem(REFRESH_TOKEN_KEY, params.refreshToken);
});

const setPaginationFx = createEffect(() => {
  if (!localStorage.getItem(TABLE_ENTRIES_PER_PAGE)) {
    localStorage.setItem(
      TABLE_ENTRIES_PER_PAGE,
      entriesPerPageOptions[0].value,
    );
  }
});

sample({
  clock: initAuth,
  target: [initAuthFx, setPaginationFx],
});

sample({
  clock: setAuth,
  target: [setAuthFx, setPaginationFx],
});

sample({
  clock: initAuthFx.failData,
  target: refreshAuthFx,
});

sample({
  clock: refreshAuthFx.failData,
  target: clearTokensFx,
});

sample({
  clock: [setAuthFx.failData, clearTokensFx.failData],
  fn: () => false,
  target: $isAuth,
});

sample({
  clock: [initAuthFx.doneData, refreshAuthFx.doneData, setAuthFx.done],
  fn: () => true,
  target: $isAuth,
});

export const isAuth = {
  stores: {
    $isAuth,
    $pending: pending({ effects: [initAuthFx, refreshAuthFx] }),
  },
  events: {
    setAuth,
    initAuth,
  },
};
