import { Effect, sample } from 'effector';
import { showToastEvent } from 'entities/Toast/model/showToast';
import { EToastStatus, IShowToast } from 'entities/Toast/types';
import i18next from 'i18next';
import { TLocaleKey } from 'shared/locales/i18n';

export const toastEffect = <P, D, Q>(
  effect: Effect<P, D, Q>,
  messages?: {
    errorMessage?: string;
    successMessage?: string;
  },
) => {
  if (messages?.errorMessage)
    sample({
      clock: effect.failData,
      fn: (): IShowToast => ({
        status: EToastStatus.Error,
        messageKey: messages.errorMessage as TLocaleKey,
      }),
      target: showToastEvent,
    });
  if (messages?.successMessage)
    sample({
      clock: effect.doneData,
      fn: (): IShowToast => ({
        status: EToastStatus.Success,
        messageKey: messages.successMessage as TLocaleKey,
      }),
      target: showToastEvent,
    });
  return effect;
};
