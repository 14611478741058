import { createForm } from 'effector-forms';
import { rules } from 'shared/lib/rules';

export const otpFormFactory = () => {
  return createForm({
    fields: {
      pinCode: {
        init: '',
        rules: [rules.otpPinCodeValidate()],
      },
    },
    validateOn: ['change', 'submit'],
  });
};
