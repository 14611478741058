import React from 'react';
import { ErrorBoundary } from 'app/ErrorBoundary';
import { Route } from 'react-router-dom';

import { TRoute } from 'router/routes/types';
import RouteGuard from 'widgets/RouteGuard';

export const mapRoutes = (routes: TRoute) =>
  routes &&
  Object.values(routes).map(
    ({ path, component, children, isOutlet, permissionKey, hidePaths }) => (
      <Route
        key={path}
        path={path}
        element={
          <ErrorBoundary>
            <RouteGuard
              permissionKey={permissionKey}
              component={component}
              path={path}
              isOutlet={isOutlet}
              hidePaths={hidePaths}
            />
          </ErrorBoundary>
        }>
        {mapRoutes(children)}
      </Route>
    ),
  );
