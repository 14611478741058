import { Rule } from 'effector-forms';
import i18next from 'i18next';
import {
  HASHED_PASSWORD,
  RANGE_LENGTH,
  REQUIRED,
} from 'shared/config/constants';
import { EMAIL, GLOBAL_SPACE } from '../config/regulars';
import bcrypt from 'bcryptjs';

export const rules = {
  requiredEnum: <T>(errorKey: string): Rule<T> => ({
    name: errorKey,
    validator: (value) => ({
      isValid: Boolean(value as T),
      errorText: `errors.${errorKey}`,
    }),
  }),
  requiredBoolean: (): Rule<boolean> => ({
    name: 'requiredBoolean',
    validator: (value) => ({
      isValid: typeof value === 'boolean',
      errorText: REQUIRED,
    }),
  }),
  requiredString: (text?: string): Rule<string> => ({
    name: 'requiredString',
    validator: (value) => ({
      isValid: Boolean(value?.trim()),
      errorText: text || REQUIRED,
    }),
  }),
  requiredArrayStrings: (text?: string): Rule<string[]> => ({
    name: 'requiredArrayStrings',
    validator: (value) => ({
      isValid: Boolean(value?.length && value?.map((item) => item.trim())),
      errorText: text || REQUIRED,
    }),
  }),
  invalidMaxLength: (max: number): Rule<string> => ({
    name: 'invalidMaxLength',
    validator: (value) => ({
      isValid: value.length <= max,
      errorText: i18next.t('errors.invalidMaxLength', { max }),
    }),
  }),
  invalidMinLength: (min: number): Rule<string> => ({
    name: 'invalidMinLength',
    validator: (value) => ({
      isValid: value.length >= min,
      errorText: i18next.t('errors.invalidMinLength', { min }),
    }),
  }),
  invalidLength: (
    minNumber: number,
    maxNumber: number,
    isFieldNotRequired?: boolean,
  ): Rule<string> => ({
    name: 'invalidLength',
    validator: (value) => ({
      isValid:
        (value.length <= maxNumber && value.length >= minNumber) ||
        (isFieldNotRequired && value.length === 0),
      errorText: i18next.t(RANGE_LENGTH, {
        min: minNumber,
        max: maxNumber,
      }),
    }),
  }),
  invalidRegexPattern: (errorKey: string, pattern: RegExp): Rule<string> => ({
    name: errorKey,
    validator: (value) => ({
      isValid: pattern.test(value),
      errorText: `errors.${errorKey}`,
    }),
  }),
  invalidRepeatPassword: (): Rule<string> => ({
    name: 'invalidRepeatPassword',
    validator: (value, form) => ({
      isValid: value === form.password,
      errorText: 'errors.passDontMatch',
    }),
  }),
  invalidOldPassword: (): Rule<string> => ({
    name: 'invalidOldPassword',
    validator: (value) => {
      const hash = localStorage.getItem(HASHED_PASSWORD);
      const isValid = hash ? bcrypt.compareSync(value, hash) : false;
      return {
        isValid,
        errorText: 'errors.invalidOldPassword',
      };
    },
  }),
  otpPinCodeValidate: (): Rule<string> => ({
    name: 'otpPinCodeValidate',
    validator: (value) => ({
      isValid: Boolean(value && value.replace(GLOBAL_SPACE, '').length === 6),
      errorText: 'errors.invalidPinCodeLength',
    }),
  }),
  emailPattern: (): Rule<string> => ({
    name: 'emailPattern',
    validator: (value) => ({
      isValid: EMAIL.test(value),
      errorText: 'errors.invalidEmail',
    }),
  }),
  arrayLength: (): Rule<string[] | number[]> => ({
    name: 'arrayLength',
    validator: (value) => ({
      isValid: Boolean(value?.length),
      errorText: 'errors.arrayLength',
    }),
  }),
};
