import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { Box, Stack } from '@chakra-ui/react';
import { profileModel } from 'entities/Profile/model';
import LogOutIcon from 'shared/assets/svg/authentication/icSignOut.svg?react';
import { Button } from 'shared/ui/atoms/Button';
import { Text } from 'shared/ui/atoms/Text';
import { logoutModal } from '../LogoutModal/model/modal';

export const LeftProfile = () => {
  const { t } = useTranslation();
  const user = useUnit(profileModel.selfUser.stores.$selfUser);

  const logOutHandler = () => {
    logoutModal.setVisible(true);
  };

  return (
    <Box marginTop='auto'>
      <Text size='medium' props={{ marginBottom: '0.5rem', fontWeight: 500 }}>
        {t('leftSidebar.profile.labelProfile')}
      </Text>

      {user && (
        <Stack marginBottom={5}>
          <Text
            props={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
            size='small'>{`${user.firstName} ${user.lastName}`}</Text>
          <Text
            props={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
            size='small'>
            {user.email}
          </Text>
        </Stack>
      )}

      <Button
        color='warning'
        props={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          gap: 2,
        }}
        onClick={logOutHandler}>
        <LogOutIcon />
        <p>{t('leftSidebar.profile.buttonLogOut')}</p>
      </Button>
    </Box>
  );
};
