import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const wrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const circle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-bottom: 3px;
  font-weight: 600;
  font-size: 12px;
  line-height: 32px;
  user-select: none;

  ${theming((theme) => ({
    backgroundColor: theme.authentication.stepperBg,
    color: theme.authentication.textInactiveStepper,
  }))}

  &[data-isactive='true'] {
    ${theming((theme) => ({
      backgroundColor: theme.authentication.stepperBgActive,
      color: theme.authentication.text,
    }))}
  }
`;

const title = css`
  font-weight: 500;
  font-size: 12px;
  line-height: 32px;
  padding: 0;
  user-select: none;

  &[data-isactive='false'] {
    ${theming((theme) => ({
      color: theme.authentication.textInactiveStepper,
    }))}
  }
`;

export const stepperItemCss = {
  wrapper,
  circle,
  title,
};
