import { AlertStatus } from '@chakra-ui/react';
import { ReactText } from 'react';
import { TLocaleKey } from 'shared/locales/i18n';

export enum EToastStatus {
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
  Success = 'success',
}

interface ITranslateParams {
  [key: string]: string;
}

export interface IShowToast {
  duration?: number;
  status: AlertStatus;
  messageKey: TLocaleKey;
  translateParams?: ITranslateParams;
}

export type TShowToast = (params: IShowToast) => ReactText;
