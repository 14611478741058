import { createEvent, createStore, sample } from 'effector';

import { EAuthStages } from '../types';

const setStage = createEvent<EAuthStages>();
const $currentStage = createStore<EAuthStages>(EAuthStages.LOGIN);

sample({
  clock: setStage,
  target: $currentStage,
});

export const stage = {
  stores: {
    $currentStage,
  },
  events: {
    setStage,
  },
};
