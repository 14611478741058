import { useTranslation } from 'react-i18next';

import key from 'shared/assets/images/authentication/key.png';
import { Button } from 'shared/ui/atoms/Button';
import { authPagesModel } from 'pages/Auth/model';
import { Box, Heading, Image } from '@chakra-ui/react';
import { Text } from 'shared/ui/atoms/Text';

export const InitialStep = () => {
  const { t } = useTranslation();

  const onClick = () => {
    authPagesModel.otpSteps.events.setCurrentStep(1);
  };

  return (
    <Box
      width='600px'
      display='flex'
      flexDirection='column'
      alignItems='center'>
      <Image marginBottom={5} src={key} alt='key' />

      <Heading size='md' marginBottom={5}>
        {t('autentication.initial.title')}
      </Heading>

      <Text props={{ marginBottom: 8, textAlign: 'center' }}>
        {t('autentication.initial.description')}
      </Text>

      <Button onClick={onClick}>{t('autentication.initial.button')}</Button>
    </Box>
  );
};
