import { useUnit } from 'effector-react';
import React from 'react';

import { stepperConfig } from 'pages/Auth/config';
import { authPagesModel } from 'pages/Auth/model';
import { StepperItem } from '../StepperItem';
import { stepperCss } from './style';

export const Stepper = () => {
  const currentStep = useUnit(authPagesModel.otpSteps.stores.$currentStep);

  return (
    <div className={stepperCss.wrapper}>
      {stepperConfig.map((el, index) => (
        <React.Fragment key={`${el.key}_${index}`}>
          <StepperItem
            number={index + 1}
            titleKey={el.title}
            isActive={currentStep >= index + 1}
          />

          {stepperConfig.length - 1 > index && (
            <div
              data-isactive={currentStep >= index + 2}
              className={stepperCss.line}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
