import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';

import {
  $searchContainerVisible,
  setSearch,
  setSearchContainerVisible,
} from 'entities/Search/model/search';
import SearchResults from '../SearchResults';

import { wrapperContainerClass, inputClass } from './style';
import SearchIcon from 'shared/assets/svg/SearchLoupe.svg?react';
import { Flex } from '@chakra-ui/react';

const SearchContainer: React.FC = () => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const searchContainerVisible = useUnit($searchContainerVisible);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (searchContainerVisible) inputRef?.current?.focus();
  }, [searchContainerVisible, inputRef?.current]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setSearchContainerVisible(false);
    }
  };

  return (
    <div ref={wrapperRef} className={wrapperContainerClass}>
      <Flex alignItems='center'>
        <SearchIcon />
        <input
          ref={inputRef}
          onChange={onSearch}
          className={inputClass}
          type='text'
          placeholder={t('search.placeholder')}
        />
      </Flex>
      <SearchResults />
    </div>
  );
};

export default SearchContainer;
