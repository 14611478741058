import React, { ReactNode } from 'react';
import { Button } from 'shared/ui/atoms/Button';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: string;
  errorInfo: string;
}
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: '', errorInfo: '' };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState((prev) => ({
      ...prev,
      error: error.message,
      errorInfo: errorInfo.componentStack,
    }));
  }

  onReload() {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: '15px' }}>
          <h1>Oops, something went wrong</h1>
          <h2>Try refreshing the page</h2>
          <br />
          <article>
            <h3>Error:</h3>
            <p>{this.state.error}</p>
            <br />
            <h3>Stack trace:</h3>
            <pre>{this.state.errorInfo}</pre>
          </article>
          <br />
          <Button onClick={this.onReload}>Refresh</Button>
        </div>
      );
    }

    return this.props.children;
  }
}
