import { css } from '@linaria/core';

const input = css`
  background: transparent;
`;

const inputWrapper = css`
  width: 350px;
`;

export const thirdStepCss = {
  input,
  inputWrapper,
};
