import { createStore, Effect } from 'effector';
import { EEffectState } from './types';
import { debug } from 'patronum';

export const stateEffect = <P, D, Q>(effect: Effect<P, D, Q>) => {
  const $state = createStore<EEffectState>(EEffectState.IDLE)
    .on(effect.pending, (state, payload) =>
      payload ? EEffectState.PENDING : state,
    )
    .on(effect.done, () => EEffectState.SUCCESS)
    .on(effect.fail, () => EEffectState.ERROR)
    .reset(effect);
  return { $state, effect };
};
