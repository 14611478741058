import { css } from '@linaria/core';

export const errorClass = css`
  padding: 0;
  margin: 2px 0 2px;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  min-height: 20px;

  &[data-error-row-count='2'] {
    height: 40px;
  }

  color: #eb5757;

  &[data-isdescription='true'] {
    color: #81869a;
  }
`;
