import { css } from '@linaria/core';
import { theming } from 'shared/theme';

export const svgWrapperClass = css`
  width: 18px;
  height: 18px;
  z-index: 2;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
  }

  ${theming((theme) => ({
    color: theme.input.default.borderColor,
  }))}
`;
