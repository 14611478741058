import React from 'react';
import { useTranslation } from 'react-i18next';

import icAppStore from 'shared/assets/images/authentication/icAppStore.png';
import icGooglePlay from 'shared/assets/images/authentication/icGooglePlay.png';
import icQrAndroid from 'shared/assets/images/authentication/icQrAndroid.png';
import icQrIos from 'shared/assets/images/authentication/icQrIos.png';
import { ISelectDeviceItem } from './types';
import { Box, VStack } from '@chakra-ui/react';
import { Text } from 'shared/ui/atoms/Text';

export const SelectDeviceItem: React.FC<ISelectDeviceItem> = ({
  type,
  isActive = false,
  onClick = () => {},
}) => {
  const { t } = useTranslation();

  const onItemClick = () => {
    onClick(type);
  };

  return (
    <VStack
      width='120px'
      cursor='pointer'
      userSelect='none'
      borderRadius={1}
      padding={3}
      onClick={onItemClick}
      background={isActive ? 'rgba(255, 255, 255, 0.4)' : 'transparent'}>
      <img src={type === 'ios' ? icAppStore : icGooglePlay} />

      <Text color='mediumGray' size='small'>
        {t('autentication.step1.download')}
      </Text>

      <Box width='90px' height='90px'>
        <img src={type === 'ios' ? icQrIos : icQrAndroid} />
      </Box>

      <Text size='small' props={{ fontWeight: 600 }}>
        {t(`autentication.step1.${type === 'ios' ? 'appStore' : 'googlePlay'}`)}
      </Text>
    </VStack>
  );
};
