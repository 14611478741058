import React from 'react';
import SpinnerIcon from 'shared/assets/svg/Spinner.svg?react';
import { defaultWrapperClass, spinnerClass } from './style';
import { ISpinner } from './types';

const Spinner: React.FC<ISpinner> = ({ className, isInTable = false }) => {
  if (isInTable)
    return (
      <tbody className={`${defaultWrapperClass} ${className}`}>
        <tr>
          <td>
            <SpinnerIcon className={spinnerClass} width={32} height={32} />
          </td>
        </tr>
      </tbody>
    );

  return (
    <div className={`${defaultWrapperClass} ${className}`}>
      <SpinnerIcon className={spinnerClass} width={32} height={32} />
    </div>
  );
};

export default Spinner;
