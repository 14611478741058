import { createEvent, createStore, forward, sample } from 'effector';
import { debounce } from 'patronum';

import { SEARCH_DEBOUNCE_TIMEOUT } from 'shared/config/constants';
import { TSearchMapItem } from '../types';
import { searchMap } from '../config/searchMap';

const setSearchContainerVisible = createEvent<boolean>();
const setSearch = createEvent<string>();
const onResultClick = createEvent();

const $searchContainerVisible = createStore<boolean>(null);
const $search = createStore<string>(null);
const $results = createStore<TSearchMapItem[]>(null);

const changeSearchDebounce = debounce({
  source: $search,
  timeout: SEARCH_DEBOUNCE_TIMEOUT,
});

sample({
  clock: onResultClick,
  fn: () => null,
  target: $results,
});

sample({
  clock: onResultClick,
  fn: () => false,
  target: setSearchContainerVisible,
});

sample({
  clock: changeSearchDebounce,
  fn: (search) => {
    const res = searchMap.filter((item) =>
      item.searchVariants
        .map((item) => item.includes(search))
        .some((item) => Boolean(item)),
    );
    if (!search) return null;
    return res;
  },
  target: $results,
});

forward({
  from: setSearch,
  to: $search,
});

forward({
  from: setSearchContainerVisible,
  to: $searchContainerVisible,
});

export {
  $searchContainerVisible,
  setSearchContainerVisible,
  $search,
  setSearch,
  $results,
  onResultClick,
};
