import IconCommission from 'shared/assets/svg/sidebar/commission.svg?react';
import IconInfographic from 'shared/assets/svg/sidebar/infographic.svg?react';
import IconSettings from 'shared/assets/svg/sidebar/settings.svg?react';
import IconUser from 'shared/assets/svg/sidebar/user.svg?react';
import IconUsers from 'shared/assets/svg/sidebar/users.svg?react';

export const leftSidebarIcons = {
  users: <IconUsers />,
  access: <IconUser />,
  commission: <IconCommission />,
  infographic: <IconInfographic />,
  settings: <IconSettings />,
};
