import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'effector-forms';
import { useUnit } from 'effector-react';
import { authPagesModel } from 'pages/Auth/model';
import { EAuthStages } from 'pages/Auth/types';
import { Button } from 'shared/ui/atoms/Button';
import { FirstStep } from '../FirstStep';
import { SecondStep } from '../SecondStep';
import { ThirdStep } from '../ThirdStep';
import { FourthStep } from '../FourthStep';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'shared/config/constants';
import { Box, Flex, Heading } from '@chakra-ui/react';

export const CurrentStep: FC = () => {
  const [loginData, currentStep] = useUnit([
    authPagesModel.login.stores.$loginData,
    authPagesModel.otpSteps.stores.$currentStep,
  ]);
  const { submit } = useForm(authPagesModel.enableOtp.form);
  const { t } = useTranslation();

  const onPrev = () => {
    authPagesModel.otpSteps.events.setCurrentStep(currentStep - 1);
  };

  const onNext = () => {
    if (currentStep === 1) {
      authPagesModel.generateOtp.events.generateOTP(loginData.user.id);
      return;
    }
    if (currentStep === 3) {
      submit();
      return;
    }
    authPagesModel.otpSteps.events.setCurrentStep(currentStep + 1);
  };

  const onFinaly = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    authPagesModel.stage.events.setStage(EAuthStages.LOGIN);
  };

  const getStep = () => {
    switch (currentStep) {
      case 1:
        return <FirstStep />;
      case 2:
        return <SecondStep />;
      case 3:
        return <ThirdStep />;
      case 4:
        return <FourthStep />;
      default:
        return null;
    }
  };

  return (
    <>
      <Heading size='sm' marginBottom={10}>
        {t(`autentication.step${currentStep}.title`)}
      </Heading>

      <Box width='350px'>
        <Box marginBottom={10}>{getStep()}</Box>
        {currentStep < 4 ? (
          <Flex gap={4}>
            {currentStep !== 1 && (
              <Button
                props={{ width: '100%' }}
                color='secondary'
                onClick={onPrev}>
                {t('autentication.previous')}
              </Button>
            )}

            <Button props={{ width: '100%' }} onClick={onNext}>
              {t('autentication.next')}
            </Button>
          </Flex>
        ) : (
          <Button props={{ width: '100%' }} onClick={onFinaly}>
            {t('autentication.complete')}
          </Button>
        )}
      </Box>
    </>
  );
};
