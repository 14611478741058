import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FC } from 'react';
import { IModal } from './types';
import { Text } from '../Text';
import { useTranslation } from 'react-i18next';

export const CustomModal: FC<IModal> = ({
  isOpen,
  onRequestClose,
  children,
  footer,
  title,
  withCloseButton = false,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      closeOnOverlayClick
      isCentered
      blockScrollOnMount={true}
      isOpen={isOpen}
      onClose={onRequestClose}>
      <ModalOverlay />
      <ModalContent>
        {onSubmit ? (
          <form onSubmit={onSubmit}>
            {title && (
              <ModalHeader textAlign='center'>
                <Text size='large'>{t(title)}</Text>
              </ModalHeader>
            )}
            {withCloseButton && <ModalCloseButton />}
            <ModalBody padding={6}>{children}</ModalBody>
            <ModalFooter>{footer}</ModalFooter>
          </form>
        ) : (
          <>
            {title && (
              <ModalHeader textAlign='center'>
                <Text size='large'>{t(title)}</Text>
              </ModalHeader>
            )}
            {withCloseButton && <ModalCloseButton />}
            <ModalBody padding={6}>{children}</ModalBody>
            <ModalFooter>{footer}</ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
