export enum ESignInErrors {
  WRONG_PASSWORD = 'WRONG_PASSWORD',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
}

export enum StepperKeys {
  STEP1 = 'STEP1',
  STEP2 = 'STEP2',
  STEP3 = 'STEP3',
  STEP4 = 'STEP4',
  STEP5 = 'STEP5',
}

export const stepperConfig = [
  {
    key: StepperKeys.STEP1,
    title: 'autentication.stepper.step1',
  },
  {
    key: StepperKeys.STEP2,
    title: 'autentication.stepper.step2',
  },
  {
    key: StepperKeys.STEP3,
    title: 'autentication.stepper.step3',
  },
  {
    key: StepperKeys.STEP4,
    title: 'autentication.stepper.step4',
  },
];
