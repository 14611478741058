import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat, PatternFormat } from 'react-number-format';

import { TLocaleKey } from 'shared/locales/i18n';
import { EInputFormatType, IInput } from './types';
import { Svg } from '../Svg';

import { errorClass } from 'shared/css';
import {
  borderWrapperClass,
  classWrapperDefaultClass,
  clearFieldButtonClass,
  iconErrorClass,
  inputClass,
  inputWrapper,
  labelClass,
  leftContentWrapperClass,
  rightContentWrapperClass,
} from './style';
import ErrorExclamationIcon from 'shared/assets/svg/ErrorExclamation.svg?react';

export const Input: React.FC<IInput> = ({
  labelKey,
  errorKey,
  classInput = '',
  classLabel = '',
  classWrapper = '',
  borderClass = '',
  inputProps = {},
  errorRowCount = 1,
  isWithError = true,
  isAllowed,
  inputRef,
  onValueChange = () => {},
  onClearClick = null,
  rightContent,
  leftContent,
  iconClass,
  descriptionKey,
  format = {
    type: EInputFormatType.TEXT,
  },
}) => {
  const { t } = useTranslation();
  const renderNumericInput = () =>
    format.type === EInputFormatType.NUMERIC ? (
      <NumericFormat
        getInputRef={inputRef}
        decimalSeparator='.'
        thousandSeparator=' '
        className={`${inputClass} ${classInput}`}
        onChange={inputProps.onChange}
        onBlur={inputProps.onBlur}
        name={inputProps.name}
        value={inputProps.value as string}
        placeholder={inputProps.placeholder}
        autoComplete='off'
        defaultValue='0.00'
        suffix={format.props?.suffix}
        allowNegative={false}
        disabled={inputProps.disabled}
        data-is-error={Boolean(errorKey)}
        decimalScale={format.props?.decimalScale}
        maxLength={format.props?.maxLength}
        allowedDecimalSeparators={[',']}
        onValueChange={onValueChange}
        isAllowed={isAllowed}
      />
    ) : null;

  const renderPatternInput = () =>
    format.type === EInputFormatType.PATTERN ? (
      <PatternFormat
        getInputRef={inputRef}
        className={`${inputClass} ${classInput}`}
        onChange={inputProps.onChange}
        onBlur={inputProps.onBlur}
        name={inputProps.name}
        value={inputProps.value as string}
        placeholder={inputProps.placeholder}
        autoComplete='off'
        defaultValue=''
        disabled={inputProps.disabled}
        data-is-error={Boolean(errorKey)}
        mask={format.props.mask}
        format={format.props.format}
        allowEmptyFormatting={format.props.allowEmptyFormatting}
        onValueChange={onValueChange}
        isAllowed={isAllowed}
      />
    ) : null;

  return (
    <div
      className={`${classWrapperDefaultClass} ${classWrapper}`}
      data-is-with-label={Boolean(labelKey)}>
      <div className={inputWrapper}>
        {leftContent && (
          <div
            className={`${leftContentWrapperClass} ${iconClass}`}
            data-is-error={Boolean(errorKey)}>
            {leftContent}
          </div>
        )}
        {format.type === EInputFormatType.NUMERIC ? (
          renderNumericInput()
        ) : format.type === EInputFormatType.PATTERN ? (
          renderPatternInput()
        ) : (
          <input
            className={`${inputClass} ${classInput}`}
            ref={inputRef}
            data-is-error={Boolean(errorKey)}
            data-is-left-content={Boolean(leftContent)}
            data-is-right-content={Boolean(rightContent)}
            {...inputProps}
          />
        )}
        <div className={`${borderWrapperClass} ${borderClass}`} />
        {/*  TODO fix label */}
        {labelKey && (
          <label
            className={`${labelClass} ${classLabel}`}
            htmlFor={inputProps?.name}>
            {t(labelKey)}
          </label>
        )}
        {errorKey && (
          <div
            className={iconErrorClass}
            data-is-with-label={Boolean(labelKey)}>
            <ErrorExclamationIcon width={18} height={18} />
          </div>
        )}
        {rightContent && (
          <div
            className={`${rightContentWrapperClass} ${iconClass}`}
            data-is-error={Boolean(errorKey)}
            data-is-with-label={Boolean(labelKey)}>
            {rightContent}
          </div>
        )}
        {Boolean(onClearClick && inputProps?.value) && (
          <button className={clearFieldButtonClass} onClick={onClearClick}>
            <Svg idIcon='icCross' spriteName='common' />
          </button>
        )}
      </div>
      {isWithError && (
        <p
          className={errorClass}
          data-error-row-count={errorRowCount}
          data-isdescription={Boolean(descriptionKey && !errorKey)}>
          {errorKey && t(errorKey as TLocaleKey)}
          {descriptionKey && !errorKey && t(descriptionKey)}
        </p>
      )}
    </div>
  );
};
