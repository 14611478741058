import { useUnit } from 'effector-react';
import { FC } from 'react';

import { Box } from '@chakra-ui/react';
import { authPagesModel } from 'pages/Auth/model';
import { EAuthDevices } from 'pages/Auth/types';
import { SelectDeviceItem } from '../SelectDeviceItem';

export const FirstStep: FC = () => {
  const selectedDevice = useUnit(authPagesModel.device.stores.$selectedDevice);

  const onClick = (type: EAuthDevices) => () => {
    authPagesModel.device.events.setSelectedDevice(type);
  };

  return (
    <Box display='flex' justifyContent='space-between'>
      <SelectDeviceItem
        type='ios'
        isActive={selectedDevice === EAuthDevices.IOS}
        onClick={onClick(EAuthDevices.IOS)}
      />

      <SelectDeviceItem
        type='android'
        isActive={selectedDevice === EAuthDevices.ANDROID}
        onClick={onClick(EAuthDevices.ANDROID)}
      />
    </Box>
  );
};
