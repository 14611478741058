export enum EAuthStages {
  LOGIN = 'LOGIN',
  VERIFY_OTP = 'VERIFY_OTP',
  SET_PASSWORD = 'SET_PASSWORD',
  ENABLE_OTP = 'ENABLE_OTP',
}

export enum EAuthDevices {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
}
