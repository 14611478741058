import { useTranslation } from 'react-i18next';

import { Image, VStack } from '@chakra-ui/react';
import icCheck from 'shared/assets/images/authentication/icCheck.png';
import { Text } from 'shared/ui/atoms/Text';

export const FourthStep = () => {
  const { t } = useTranslation();

  return (
    <VStack>
      <Image width='64px' height='64px' marginBottom={10} src={icCheck} />

      <Text size='small' props={{ textAlign: 'center' }}>
        {t('autentication.step4.description')}
      </Text>
    </VStack>
  );
};
