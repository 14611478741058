export enum ETheme {
  light = 'theme-light',
  dark = 'theme-dark',
}
export enum ECacheKeys {
  SCOPES = 'scopes',
}
export enum ESpriteName {
  common = 'common',
}
export enum ERoles {
  GUEST = 'GUEST',
  SUPER = 'SUPER',
  AML = 'AML',
  ADMIN = 'ADMIN',
}
export enum EDevice {
  ANDROID = 'android',
  IOS = 'ios',
  WEB = 'web',
}
