import { useForm } from 'effector-forms';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { TOnChange } from 'shared/config/types';
import { Input } from 'shared/ui/atoms/Input';
import { EInputFormatType } from 'shared/ui/atoms/Input/types';
import { PIN_CODE_MASK, PIN_CODE_PATTERN } from '../../config';
import { IOtpCodeForm } from './types';

export const OTPCodeForm: FC<IOtpCodeForm> = ({ form }) => {
  const { t } = useTranslation();
  const { fields, errorText, reset } = useForm(form);
  const inputRef = useRef<HTMLInputElement>(null);

  const onChange: TOnChange = (e) => {
    fields[e.target.name].onChange(e.target.value);
  };

  useEffect(() => {
    inputRef?.current?.focus();
    return reset;
  }, []);

  return (
    <Input
      inputRef={inputRef}
      format={{
        type: EInputFormatType.PATTERN,
        props: {
          format: PIN_CODE_PATTERN,
          mask: PIN_CODE_MASK,
        },
      }}
      labelKey='otpCodeModal.label'
      inputProps={{
        placeholder: t('otpCodeModal.codePlaceholder'),
        name: fields.pinCode.name,
        value: fields.pinCode.value,
        type: 'number',
        onChange,
      }}
      descriptionKey='otpCodeModal.description'
      errorKey={errorText('pinCode')}
    />
  );
};
