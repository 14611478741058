import { useUnit } from 'effector-react';

import { OTPCodeModal } from 'entities/Auth/ui/OTPCodeModal';
import { renderContent } from './lib/renderContent';
import { authPagesModel } from './model';
import { EAuthStages } from './types';
import { LoginForm } from './ui/LoginForm';

import { authModel } from 'entities/Auth/model';
import { SpinnerBlock } from 'shared/ui/molecules/SpinnerBlock';

export const Auth = () => {
  const [currentStage, pending] = useUnit([
    authPagesModel.stage.stores.$currentStage,
    authModel.isAuth.stores.$pending,
  ]);

  const onCancelSignIn = () => {
    authPagesModel.stage.events.setStage(EAuthStages.LOGIN);
  };

  const getContent = () => {
    if (renderContent[currentStage]) return renderContent[currentStage];
    return <LoginForm />;
  };

  return (
    <>
      {pending && <SpinnerBlock size={60} />}
      {getContent()}
      <OTPCodeModal
        onCancel={onCancelSignIn}
        modalData={authPagesModel.verifyOtp.modal}
        form={authPagesModel.verifyOtp.form}
      />
    </>
  );
};
