export const colorConfig = {
  primary: 'blue',
  secondary: 'gray',
  success: 'green',
  danger: 'red',
  warning: 'orange',
};

export type TComponentColor =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning';
