import { createEffect, createEvent, createStore, sample } from 'effector';
import { createForm } from 'effector-forms';
import { showToastEvent } from 'entities/Toast/model/showToast';
import { EToastStatus, IShowToast } from 'entities/Toast/types';
import { requestsClient } from 'shared/api/client';

import {
  ELoginType,
  LoginMutationVariables,
  LoginResponse,
} from 'shared/api/apollo/__generated__';
import { rules } from 'shared/lib/rules';
import { toastEffect } from 'shared/lib/toastEffect';
import { spread } from 'patronum';
import { stage } from './stage';
import { EAuthStages } from '../types';

const form = createForm({
  fields: {
    email: {
      init: '',
      rules: [rules.requiredString()],
    },
    password: {
      init: '',
      rules: [rules.requiredString(), rules.invalidLength(8, 128)],
    },
  },
  validateOn: ['change', 'submit'],
});

const loginFx = toastEffect(
  createEffect<LoginMutationVariables, LoginResponse>(async (params) => {
    const response = await requestsClient.login(params);
    return response.login as LoginResponse;
  }),
  { errorMessage: 'authorization.errors.invalid' },
);

const $loginData = createStore<LoginResponse>(null);

sample({
  clock: form.formValidated,
  fn: (form) => ({
    input: {
      email: form.email,
      password: form.password,
    },
  }),
  target: loginFx,
});

sample({
  clock: loginFx.doneData,
  target: $loginData,
});

const showConfirmEmailToast = createEvent<boolean>();

sample({
  clock: showConfirmEmailToast,
  fn: (): IShowToast => ({
    status: EToastStatus.Error,
    messageKey: 'confirmEmail.needConfirm',
    duration: 10000,
  }),
  target: showToastEvent,
});

sample({
  clock: $loginData,
  fn: (data) => ({
    ...(data.type === ELoginType.SetPassword && {
      setPassword: EAuthStages.SET_PASSWORD,
    }),
    ...(data.type === ELoginType.GenerateOtp && {
      enableOtp: EAuthStages.ENABLE_OTP,
    }),
    ...(data.type === ELoginType.VerifyOtp && {
      verifyOtp: EAuthStages.VERIFY_OTP,
    }),
    ...(data.type === ELoginType.ConfirmEmail && { confirmEmail: true }),
  }),
  target: spread({
    targets: {
      setPassword: stage.events.setStage,
      enableOtp: stage.events.setStage,
      verifyOtp: stage.events.setStage,
      confirmEmail: showConfirmEmailToast,
    },
  }),
});

export const login = {
  stores: {
    $loginData,
  },
  form,
};
