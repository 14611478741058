import { exceptionList } from './config/exceptionList';
import { breadcrumbsCss } from './style';

export const getLinkItemName = (
  paramsEntries: string[],
  link: string,
  itemIdName: string,
  arr: string[],
  index: number,
): JSX.Element => {
  const name = () => {
    if (
      !paramsEntries?.length ||
      exceptionList.includes(paramsEntries[0]) ||
      paramsEntries[1] !== link
    ) {
      if (exceptionList.includes(paramsEntries?.[0])) return link;
      return link
        .replaceAll('-', ' ')
        .split(/(?=[A-Z])/)
        .join(' ');
    }
    return itemIdName ? itemIdName : link;
  };

  return (
    <>
      {name()}{' '}
      {arr.length !== index + 1 && (
        <span className={breadcrumbsCss.linkSeparator}>/</span>
      )}
    </>
  );
};
