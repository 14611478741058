import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Box, Stack } from '@chakra-ui/react';
import { redirectModel } from 'router/model';
import { LeftSidebar } from './ui/LeftSidebar';
import { Navbar } from './ui/Navbar';

const Layout = () => {
  const redirectRoute = useUnit(redirectModel.$redirectRoute);
  const navigate = useNavigate();

  useEffect(() => {
    if (redirectRoute) {
      navigate(redirectRoute);
      redirectModel.setRedirectRoute(null);
    }
  }, [redirectRoute]);

  return (
    <Box display='flex' height='100vh' width={'100%'}>
      <LeftSidebar />
      <Stack gap={0}>
        <Navbar />
        <Box
          width='calc(100vw - 230px)'
          padding={4}
          position='relative'
          overflow={'auto'}>
          <Outlet />
        </Box>
      </Stack>
    </Box>
  );
};

export default Layout;
