import { useTranslation } from 'react-i18next';
import { useForm } from 'effector-forms';

import { authPagesModel } from 'pages/Auth/model';
import { PasswordInput } from 'shared/ui/molecules/PasswordInput';
import { TOnSubmit, TOnChange } from 'shared/config/types';
import { Button } from 'shared/ui/atoms/Button';
import { Card } from 'shared/ui/atoms/Card';

export const CreatePasswordForm = () => {
  const { t } = useTranslation();
  const { fields, submit, errorText } = useForm(
    authPagesModel.setPassword.form,
  );

  const onChange: TOnChange = (e) => {
    fields[e.target.name].onChange(e.target.value);
  };

  const onSubmit: TOnSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  return (
    <Card props={{ width: '500px' }} title='createPassword.title'>
      <form onSubmit={onSubmit}>
        <PasswordInput
          inputProps={{
            name: fields.password.name,
            value: fields.password.value,
            onChange,
            placeholder: t('createPassword.placeholder'),
          }}
          labelKey='createPassword.newPassword'
          errorKey={errorText('password')}
        />

        <PasswordInput
          inputProps={{
            name: fields.repeatPassword.name,
            value: fields.repeatPassword.value,
            onChange,
            placeholder: t('createPassword.placeholder'),
          }}
          labelKey='createPassword.confirmation'
          errorKey={errorText('repeatPassword')}
        />

        <Button props={{ width: '100%' }} type='submit'>
          {t('createPassword.button')}
        </Button>
      </form>
    </Card>
  );
};
