import { css } from '@linaria/core';

export const classWrapperDefaultClass = css`
  position: relative;
  width: 100%;
  min-width: 300px;
  &[data-is-with-label='true'] {
    padding-top: 18px;
  }
`;

export const labelClass = css`
  position: absolute;
  top: -25px;
  font-size: 0.875rem;
  font-weight: 500;
  color: #1f1f27;
`;

export const inputWrapper = css`
  position: relative;
  display: flex;
  align-items: center;
`;

export const borderWrapperClass = css`
  border: 2px solid;
  position: absolute;
  border-radius: 8px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.25s ease-in-out;
  border-color: #b8b8b8;
`;

export const inputClass = css`
  appearance: none;
  font-family: inherit;
  width: 100%;
  height: 40px;
  font-size: 1rem;
  font-weight: 400;
  padding: 0 16px;
  margin: 2px;
  border-radius: 8px;
  transition: all 0.25s ease-in-out;
  outline: none;
  border: none;
  z-index: 10;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: all 200000s ease-in-out 200000s;
  }

  color: #1a1c1e;
  caret-color: #1a1c1e;

  &::placeholder {
    color: #b8b8b8;
  }

  &:focus {
    ~ .${labelClass} {
      color: #3182ce;
    }
    ~ .${borderWrapperClass} {
      border-color: #3182ce;
    }
  }

  &:disabled {
    ~ .${labelClass} {
      color: #c5cbd3;
    }
    ~ .${borderWrapperClass} {
      border-color: #c5cbd3;
    }
  }

  &:invalid {
    ~ .${labelClass} {
      color: #eb5757;
    }
    ~ .${borderWrapperClass} {
      border-color: #eb5757;
    }
  }

  &[data-is-error='true'] {
    ~ .${labelClass} {
      color: #eb5757;
    }
    ~ .${borderWrapperClass} {
      border-color: #eb5757;
    }
  }
`;

export const iconErrorClass = css`
  height: 18px;
  width: 18px;
  margin-right: 16px;
`;

export const rightContentWrapperClass = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: transparent;
  margin-right: 16px;
`;

export const leftContentWrapperClass = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: transparent;
  margin-left: 16px;
`;

export const clearFieldButtonClass = css`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 8px;
  z-index: 10;

  svg {
    width: 14px;
    height: 14px;
  }
`;
