import { createEffect, createEvent, createStore, sample } from 'effector';
import {
  GenerateOtpMutationVariables,
  GenerateOtpResponse,
} from 'shared/api/apollo/__generated__';
import { requestsClient } from 'shared/api/client';
import { otpSteps } from './otpSteps';

const generateOTP = createEvent<number>();
const generateOTPFx = createEffect<
  GenerateOtpMutationVariables,
  GenerateOtpResponse
>(async (params) => {
  const res = await requestsClient.generateOtp(params);
  return res.generateOtp;
});

const $otpInfo = createStore<GenerateOtpResponse>(null);

sample({
  clock: generateOTP,
  fn: (userId) => ({
    input: {
      userId,
    },
  }),
  target: generateOTPFx,
});

sample({
  clock: generateOTPFx.doneData,
  target: $otpInfo,
});

sample({
  clock: generateOTPFx.doneData,
  fn: () => 2,
  target: otpSteps.events.setCurrentStep,
});

export const generateOtp = {
  stores: {
    $otpInfo,
  },
  events: {
    generateOTP,
  },
};
