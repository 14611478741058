import i18next, { i18n } from 'i18next';
import { initReactI18next, TFunction } from 'react-i18next';
import EN from './locales/en-us.json';
import RU from './locales/ru-rus.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: EN,
  },
  ru: {
    translation: RU,
  },
};

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
    // debug: true,
    resources,
  });

export interface Customi18n extends i18n {
  t: TFunction;
}

type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];
export type TLocaleKey = NestedKeyOf<typeof EN>;

export default i18next as Customi18n;
